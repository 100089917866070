import { ActionFunctionArgs, json, type LoaderFunctionArgs, type MetaFunction } from "@remix-run/node";
import { Form, useActionData, useNavigation, useSubmit } from "@remix-run/react";
import { Button } from "~/components/ui/button";
import DjangoApi from "~/utils/django.api";
import { serverRedirect } from "~/utils/router.server-routes";
import { sqliteSessionStorage } from "~/utils/session.server";
import { Card, CardHeader, CardTitle, CardDescription, CardContent, CardFooter } from "~/components/ui/card";
import { Label } from "~/components/ui/label";
import { Input } from "~/components/ui/input";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { AxiosError, isAxiosError } from "axios";
import { Link } from "~/utils/router.routes";

export const meta: MetaFunction = () => {
  return [{ title: "Login" }];
};

export async function loader() {
  return json({});
}

export async function action({ request }: ActionFunctionArgs) {
  const { getSession, commitSession } = sqliteSessionStorage();

  const session = await getSession(null);

  const body = await request.formData();
  const email = body.get("email") as string;
  const password = body.get("password") as string;

  const payload = {
    email,
    password,
  };

  const api = new DjangoApi();

  try {
    const response = await api.login(payload);

    session.set("sessionKey", response.data.session.sessionKey);
    session.set("user", {
      id: response.data.user.id,
      email: response.data.user.email,
      firstName: response.data.user.first_name,
      lastName: response.data.user.last_name,
      role: response.data.user.role,
    });

    const maxAge = response.data.session.max_age;

    return serverRedirect({
      path: "/",
      headers: {
        "Set-Cookie": await commitSession(session, {
          maxAge,
        }),
      },
    });
  } catch (error: any) {
    if (isAxiosError(error)) {
      const err = error as AxiosError;
      if (err.response?.status != null) {
        if (err.response?.status >= 400 && err.response?.status < 500) {
          return json({ errorMessage: "Incorrect username or password." }, { status: err.response?.status });
        } else if (err.response?.status >= 500) {
          return json(
            { errorMessage: "Internal server error. Please contact support." },
            { status: err.response?.status },
          );
        }
      }
    }

    console.error("An unexpected error occurred while logging in", error);

    return json({ errorMessage: "An unexpected error occurred. Please contact support. " });
  }
}

const loginSchema = z.object({
  email: z.string().email("Invalid email address").min(1, "Email is required"),
  password: z.string().min(1, "Password is required"),
});

type LoginFormData = z.infer<typeof loginSchema>;

export default function Index() {
  const submit = useSubmit();
  const actionData = useActionData<typeof action>();

  const form = useForm<z.infer<typeof loginSchema>>({
    resolver: zodResolver(loginSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = form;

  const navigation = useNavigation();

  function onSubmit(data: LoginFormData) {
    submit(data, { method: "post" });
  }

  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      className="flex h-full min-h-screen items-center justify-center"
      method="post"
    >
      <Card className="w-full max-w-sm">
        <CardHeader>
          <CardTitle className="text-2xl">Login</CardTitle>
          <CardDescription>Enter your email below to login to your account.</CardDescription>
        </CardHeader>
        <CardContent className="grid gap-4">
          <div className="grid gap-2">
            <Label htmlFor="email">Email</Label>
            <Input id="email" type="email" placeholder="email@example.com" {...register("email")} />
            {errors?.email && <p className="text-sm text-red-600">{errors.email.message}</p>}
          </div>
          <div className="grid gap-2">
            <div className="flex flex-row items-center justify-center">
              <Label className="w-full text-left" htmlFor="password">
                Password
              </Label>
              <Link className="w-full text-right text-xs hover:underline" to="/reset-password">
                <p>Forgot your password?</p>
              </Link>
            </div>
            <Input id="password" type="password" placeholder="Enter your password" {...register("password")} />
            {errors?.password && <p className="text-sm text-red-600">{errors.password.message}</p>}
          </div>
        </CardContent>
        <CardFooter className="flex-col">
          <Button className="w-full" type="submit">
            {navigation.state === "submitting" ? "Logging in..." : "Login"}
          </Button>
          <p className="mt-2 text-sm text-red-600">{actionData?.errorMessage ?? ""}</p>
        </CardFooter>
      </Card>
    </Form>
  );
}
